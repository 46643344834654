import "core-js/modules/es6.regexp.split";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/zippo-mdm-vue-prd/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import waves from '@/directive/waves'; // waves directive
import GeneralEditForm from '@/components/GeneralEditForm';
import { generalDetail, generalDetailNewDefault, generalDetailCopyFromAudit, generalSave, generalValueLabelList } from '@/api/general';
import { deepClone } from "../../utils";
export default {
  name: 'Index',
  components: {
    GeneralEditForm: GeneralEditForm
  },
  directives: {
    waves: waves
  },
  props: {
    tableName: {
      type: String,
      default: ''
    },
    url: {
      type: Object,
      default: null
    },
    rowKey: {
      type: String,
      default: 'c_id'
    },
    id: {
      type: String,
      default: ''
    },
    rules: {
      type: Object,
      default: {}
    },
    parentHandleAdd: {
      type: Boolean,
      default: false
    },
    submitCheck: {
      type: Function,
      default: null
    },
    onSetOptions: {
      type: Function,
      default: null
    },
    onSaveSuccess: {
      type: Function,
      default: null
    }
  },
  data: function data() {
    return {
      tableColumns: [],
      editColumns: [],
      updateColumns: [],
      editForm: {},
      originalForm: null,
      loading: true,
      currentTable: {},
      saveButtonDisabled: false
    };
  },
  computed: {
    fullRules: function fullRules() {
      var s = {};
      if (!this.isNull(this.currentTable)) {
        for (var i = 0, length = this.currentTable.length; i < length; i++) {
          var item = this.currentTable[i];
          if (item.required) {
            s[item.code] = this.getMustInputRule(item.label);
          }
        }
      }
      if (!this.isNull(this.rules)) {
        Object.assign(s, this.rules);
      }
      return s;
    },
    codes: function codes() {
      return this.$store.getters.codeDataDictionary.codes;
    }
  },
  watch: {
    currentTable: function currentTable(value) {
      // console.log('watch currentTable()')
      // this.setTableColumns()
      // this.getDetailData()
    },
    codes: function codes(value) {
      //this.setSelectOptions()
    }
  },
  created: function created() {
    this.initCurrentTable();
    this.setTableColumns();
    this.getDetailData();
    this.$emit('onEditFormCreated', this);
  },
  methods: {
    getMustInputRule: function getMustInputRule(label) {
      return [{
        required: true,
        message: this.$t('rules.common.cannotEmpty'),
        trigger: 'blur'
      }];
    },
    initCurrentTable: function initCurrentTable() {
      var _this = this;
      var tdds = this.$store.getters.tableDataDictionary.tables;
      if (tdds && tdds.length > 0) {
        this.currentTable = JSON.parse(JSON.stringify(tdds.find(function (value) {
          return value.name === _this.tableName;
        }).tableColumns));
      } else {
        this.currentTable = null;
        var randomNum = Math.round(Math.random() * 10000);
        this.$router.push('/?' + randomNum);
      }
      // console.log(this.currentTable)
    },
    setTableColumns: function setTableColumns() {
      if (this.currentTable) {
        this.tableColumns = this.currentTable; // .filter(n => n.visible)
        this.editColumns = this.tableColumns.filter(function (n) {
          return (n.visible || n.editable) && (n.virtual == null || n.virtual != 1);
        });
        this.updateColumns = this.editColumns.filter(function (n) {
          return n.editable;
        });
        // console.log(this.updateColumns)
        this.setSelectOptions();
      }
    },
    setSelectOptions: function setSelectOptions() {
      var _this2 = this;
      var _iterator = _createForOfIteratorHelper(this.tableColumns.filter(function (c) {
          return c.isOption !== null && c.editable !== null && c.isOption === true && c.editable === true;
        })),
        _step;
      try {
        var _loop = function _loop() {
          var item = _step.value;
          //console.log(item.code)
          var codeDD = _this2.codes.filter(function (n) {
            return n.propId === item.propId;
          });
          if ((item.type === 'singleSelect' || item.type === 'multiSelect') && item.hasParent === true) {
            var maxLimit = 100;
            var count = 0;
            var _iterator2 = _createForOfIteratorHelper(codeDD[0].data),
              _step2;
            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var o = _step2.value;
                count = count + _this2.getOptionCount(o);
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
            if (count > maxLimit) {
              item['cascaderUseSelect'] = true;
              //this.getSelectOptions(null, item)
            } else {
              item['cascaderUseSelect'] = false;
              item.options = codeDD[0].data;
            }
            // console.log(item.options)
          } else if (item.optionsUrl && item.optionsUrl.indexOf('/') >= 0) {
            var onSetOptions = _this2.onSetOptions;
            _this2.getOptionItemSelectOptions(item).then(function (response) {
              if (typeof onSetOptions == 'function') {
                onSetOptions(response, item);
              }
            });
          } else if (codeDD && codeDD[0] && codeDD[0].data.length > 0) {
            item.options = codeDD[0].data;
          }
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    getDetailData: function getDetailData() {
      var _this3 = this;
      // console.log('getDetailData')
      // console.log(this.url.query)
      var auditProcessInstanceId = this.$route.query.auditProcessInstanceId;
      if (auditProcessInstanceId && auditProcessInstanceId !== '') {
        this.loading = true;
        return new Promise(function (resolve, reject) {
          generalDetailCopyFromAudit(_this3.url.getUrl, auditProcessInstanceId).then(function (response) {
            if (response.data.data != null) {
              _this3.editForm = response.data.data;
            }
            if (response.data.code !== '0') {
              _this3.$notify({
                title: _this3.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 5000
              });
            }
            _this3.loading = false;
            _this3.$emit('getDetailSuccess', _this3.editForm);
            // console.log(response.data)
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            _this3.loading = false;
            reject(error);
          });
        });
      }
      if (this.id && this.id !== '') {
        // this.id = this.url.params.id
        this.loading = true;
        return new Promise(function (resolve, reject) {
          generalDetail(_this3.url.getUrl, _this3.id).then(function (response) {
            //this.loading = true
            _this3.editForm = response.data.data;
            // if(this.originalForm==null){
            //   this.originalForm = deepClone(this.editForm)
            // }
            // console.log(JSON.stringify(this.editForm))
            // console.log(JSON.stringify(this.originalForm))
            if (response.data.code !== '0') {
              _this3.$notify({
                title: _this3.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 5000
              });
            }
            setTimeout(function () {
              _this3.$emit('getDetailSuccess', _this3.editForm);
            }, 8 * 1000);
            _this3.loading = false;

            // console.log(response.data)
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            _this3.loading = false;
            reject(error);
          });
        });
      } else {
        this.loading = true;
        return new Promise(function (resolve, reject) {
          generalDetailNewDefault(_this3.url.getUrl, '0').then(function (response) {
            if (response.data.data != null) {
              _this3.editForm = response.data.data;
            }
            if (response.data.code !== '0') {
              _this3.$notify({
                title: _this3.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 5000
              });
            }
            _this3.loading = false;
            _this3.$emit('getDetailSuccess', _this3.editForm);
            // console.log(response.data)
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            _this3.loading = false;
            reject(error);
          });
        });
      }
    },
    close: function close() {
      this.loading = true;
      this.$router.back();
    },
    save: function save(formName) {
      var _this4 = this;
      // console.log(this.$refs.GeneralEditForm.$refs.editForm)
      //console.log(JSON.stringify(deepClone(this.editForm)))
      //console.log(JSON.stringify(this.originalForm))
      // if(JSON.stringify(deepClone(this.editForm))===JSON.stringify(this.originalForm)){
      //   return
      // } --数字输入框需要特殊处理，例如 0 VS "0"
      if (typeof this.submitCheck == 'function') {
        var check = this.submitCheck(this.editForm);
        if (check) {
          this.$message({
            message: check,
            type: 'warning'
          });
          return;
        }
      }
      this.$refs.GeneralEditForm.$refs.editForm.validate(function (valid) {
        if (valid) {
          var updateData = {};
          Object.assign(updateData, _this4.editForm);
          var _iterator3 = _createForOfIteratorHelper(_this4.updateColumns),
            _step3;
          try {
            for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
              var item = _step3.value;
              _this4.convertSelectValueToCasader(updateData, item);
            }
          } catch (err) {
            _iterator3.e(err);
          } finally {
            _iterator3.f();
          }
          if (_this4.parentHandleAdd && _this4.id == 0) {
            _this4.$emit('handleAdd', updateData);
            return;
          }
          _this4.$confirm(_this4.$t('messageBox.confirm.body'), _this4.$t('messageBox.confirm.title'), {
            confirmButtonText: _this4.$t('button.confirm'),
            cancelButtonText: _this4.$t('button.cancel'),
            type: 'warning'
          }).then(function () {
            //let checkResult = null
            //this.$emit('checkBeforeSave', this.editForm,val=>{checkResult=val});
            _this4.loading = true;
            _this4.saveButtonDisabled = true;
            return new Promise(function (resolve, reject) {
              generalSave(_this4.url.getUrl, _this4.id, updateData).then(function (response) {
                // console.log(response)
                if (response.data.code === '0') {
                  if (typeof _this4.onSaveSuccess == 'function') {
                    _this4.onSaveSuccess(response);
                  } else {
                    _this4.$notify({
                      title: _this4.$t('notification.success.title'),
                      message: _this4.$t('notification.success.body'),
                      type: 'success',
                      duration: 3000
                    });
                    _this4.close();
                  }
                } else {
                  _this4.$notify({
                    title: _this4.$t('notification.error.title'),
                    message: response.data.message,
                    type: 'error',
                    duration: 3000
                  });
                  _this4.saveButtonDisabled = false;
                }
                _this4.loading = false;
                resolve(response);
              }).catch(function (error) {
                _this4.loading = false;
                _this4.saveButtonDisabled = false;
                reject(error);
              });
            });
            // this.getDetailData()
          }).catch(function (error) {
            console.error(error);
            _this4.loading = false;
          });
        } else {
          console.log('validate errors found!!');
          return false;
        }
      });
      //
      this.loading = false;
    },
    getOptionItemSelectOptions: function getOptionItemSelectOptions(item) {
      var _this5 = this;
      // console.log('getOptionItemSelectOptions')
      if (item.optionsUrl && item.optionsUrl.length > 0) {
        // this.loading = true
        return new Promise(function (resolve, reject) {
          generalValueLabelList(item.optionsUrl).then(function (response) {
            item.options = response.data.data;
            // console.log(item.options)
            if (response.data.code !== '0') {
              _this5.$notify({
                title: _this5.$t('notification.error.title'),
                message: response.data.message,
                type: 'error',
                duration: 5000
              });
            }
            _this5.loading = false;
            resolve(response);
          }).catch(function (error) {
            console.log(error);
            _this5.loading = false;
            reject(error);
          });
        });
      }
    },
    getOptionCount: function getOptionCount(options) {
      if (!options.children || options.children.length == 0) {
        return 1;
      } else {
        var count = 0;
        var _iterator4 = _createForOfIteratorHelper(options.children),
          _step4;
        try {
          for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
            var c = _step4.value;
            count = count + this.getOptionCount(c);
          }
        } catch (err) {
          _iterator4.e(err);
        } finally {
          _iterator4.f();
        }
        return count;
      }
    },
    convertSelectValueToCasader: function convertSelectValueToCasader(__editForm, item) {
      if (item.cascaderUseSelect && __editForm && __editForm[item.code]) {
        var sa = __editForm[item.code].split('_');
        //console.log(sa)
        __editForm[item.code] = [parseInt(sa[0]), parseInt(sa[1])];
      }
    }
  }
};